import React from 'react'
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem } from '@material-ui/core';

function Nav(props) {
	const { user, signOut } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<AppBar position="static">
			<Toolbar>
				<Typography variant="h6" style={{flexGrow: 1}}>
					HannahVanVels.com
				</Typography>
				<Button aria-controls="user-menu" aria-haspopup="true" onClick={handleClick} style={{color: '#fff'}}>
					Hello {user.displayName}
				</Button>
				<Menu
					id="user-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={signOut}>Logout</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	);
}

export default Nav;