import React, { Component } from 'react'
import Container from '@material-ui/core/Container';
import Nav from './Nav'
import PostList from './PostList';

class Home extends Component {
  render() {
      const { user, signOut, firebase } = this.props;

      return (
        <div>
            <Nav user={user} signOut={signOut} />
            <Container maxWidth="lg" style={{paddingTop:'3rem'}} component="main">
                <PostList firebase={firebase} />
            </Container>
        </div>
      );
  }
};

export default Home;