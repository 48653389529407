import React from "react";
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const Copyright = () => (
	<Typography variant="body2" color="textSecondary" align="center">
		{'Copyright © '}
		<Link color="inherit" href="https://HannahVanVels.com">
			HannahVanVels.com
		</Link>{' '}
		{new Date().getFullYear()}
		{'.'}
	</Typography>
);

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		padding: theme.spacing(4,3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	icon: {
		margin: theme.spacing(0, 2, 0, 0),
	},
}));

const Login = props => {
	const classes = useStyles();

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Paper className={classes.paper} elevation={3}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Please sign in to continue.
				</Typography>
				<Button
					type="button"
					fullWidth
					variant="contained"
					size="large"
					color="primary"
					className={classes.submit}
					onClick={props.signIn}
				>
					<i className={'fab fa-google ' +  classes.icon} />
					Sign in with Google
				</Button>
			</Paper>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	);
};

export default Login;