import React, { Component } from 'react';
import withFirebaseAuth from 'react-with-firebase-auth'
import * as firebase from 'firebase/app';
import firestore from 'firebase/firestore';
import 'firebase/auth';
import config from './config';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Home from './components/Home';
import Login from './components/Login';

const firebaseApp = firebase.initializeApp(config.firebase);
firebase.firestore().settings({});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9575cd',
    },
    secondary: {
      main: '#ff4081',
    },
  },
});

class App extends Component {
  render() {
    const {
      user,
      signOut,
      signInWithGoogle,
    } = this.props;
    const authorized = user && config.admin_emails.includes(user.email);

    return (
      <ThemeProvider theme={theme}>
          {
            authorized
              ? <Home user={user} signOut={signOut} firebase={firebaseApp} />
              : <Login signIn={signInWithGoogle} />
          }
      </ThemeProvider>
    );
  }
}

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);